import { isNullOrEmpty } from 'commons/utils';
import httpClient from 'HttpClient/index';
import api from 'HttpClient/index';

export const uploadFile = (file) => {
  const formData = new FormData();
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  formData.append('excelFile', file);
  return api.post(`/File/readContsFromExcel`, formData, config);
};

export const isUserInWorkingGroup = async (userId, workingGroupId) => {
  if (!userId || !workingGroupId) return false;
  const users = await httpClient
    .get(`/WorkGroupInfo/${userId}/UsersIn`, { params: { page: 1, pageSize: 1000 } })
    .then((res) => {
      return res?.data?.data ?? [];
    });

  const userItem = users.find((el) => el.Id == userId);
  return !isNullOrEmpty(userItem);
};
